<template>
  <div>
    <Card>
      <template #header>Tapu Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <router-link to="tapuekle">
              <b-button variant="primary">TAPU EKLE</b-button>
            </router-link>
          </b-form-group>
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Taşınmaz Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <router-link :to="`tapuekle?update_id=${item._id.$oid}`">
            <button class="btn p-0" >
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          </router-link>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon2-files-and-folders text-dark text-md"></i>
          </button>
        </div>
      </template>
      <template v-slot:item.city_id="{ item }">
        <p >{{ getCountyName(item.city_id) }}</p>
      </template>
      <template v-slot:item.district_id="{ item }">
        <p >{{ getDistrictNameMap[item.district_id] }}</p>
      </template>
    </List>
    <!-- Create and Actions Popups -->
    <DeleteOperation 
    :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="tasinmaz" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { COUNTIES, DISTRICT } from "@/core/services/store/counties.module.js";
import axios from "axios"
export default {
  name: "systemUsers",
  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Emlak Kodu", value: "property_code" },
        { text: "İl", value: "city_id" },
        { text: "İlce", value: "district_id" },
        { text: "Ada", value: "island" },
        { text: "Parsel", value: "parcel" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      getDistrictNameMap: {}, // İlçe isimlerini saklamak için bir harita nesnesi

    };
  },

  async created() {
    this.$store.dispatch(COUNTIES);
    
    this.getList();
  },
  methods: {
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/real?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
          this.populateDistrictNames();
        })
        .catch((err) => {
          console.log(err);
        })
        
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) {
      this.$bvModal.show("modal-deleteOperationtasinmaz");
      this.deleteOperationUrl = `property/real`;
      this.deleteIds = `?user_token=${this.myUser}&real_id=${item._id.$oid}&property_id=${this.$route.params.id}`;
    },
    getCountyName(id) {
      const countyName = this.getCounties.find(county => county.il_id == id)
      if (countyName) {
        return countyName.il_adi
      }else {
        return "İL BULUNAMADI"
      }
    },
    async populateDistrictNames() {
      try {
        const items = this.items;
        for (const item of items) {
          const response = await this.$dbFunctions.get(
            process.env.VUE_APP_LOCATION_URL + "ilce?ilce_id=" + item.district_id
          );
          if (response.length > 0) {
            this.$set(this.getDistrictNameMap, item.district_id, response[0].ilce_adi);
          } else {
            this.$set(this.getDistrictNameMap, item.district_id, "İlçe Bulunamadı.");
          }
        }
      } catch (err) {
        console.error("İlçe bilgileri getirilirken bir hata oluştu:", err);
      }
      this.loading= false
    },
    
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser", "getCounties"]),
  },
};
</script>
